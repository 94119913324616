// import classListPolyfill from "classlist.js";
import domready from "domready";
import handle from "./modules/handle";
import enhance from "./modules/enhance";

// Import functions that are executed on DOMready regardless of DOM
import { enhancer as scrollListener } from "./modules/scroll-listener";
import { enhancer as responsive } from "./modules/responsive";
import { default as disableHoverStylesOnScroll } from "./modules/disable-hover-styles-on-scroll";

// Import handlers
import { handler as classToggler } from "./modules/class-toggler";
import { handler as googleAnalyticsEventHandler } from "./modules/google-analytics";

// Import enhancers
import { enhancer as accordionPanel } from "./modules/accordion-panel";
import { enhancer as autocomplete } from "./modules/autocomplete";
import { enhancer as autoplayVideo } from "./modules/autoplay-video";
import { enhancer as cookiebar } from "./modules/cookiebar";
import { enhancer as deleteProject } from "./modules/delete-project";
import { enhancer as googleAnalyticsEventEnhancer } from "./modules/google-analytics";
import { enhancer as googleMaps } from "./modules/google-maps";
import { enhancer as listPanel } from "./modules/list-panel";
import { enhancer as popover } from "./modules/popover";
import { enhancer as selectRedirect } from "./modules/select";
import { enhancer as videoEmbedder } from "./modules/video-embedder";

const executeOnReady = () => {
  disableHoverStylesOnScroll(); // Disable hover styles on scroll
  scrollListener(); // Initialise central scroll listener
  responsive(); // Set document width on resize and orientation change

  /**
   * Simple requestAnimationFrame
   * @see http://elektronotdienst-nuernberg.de/bugs/requestAnimationFrame.html
   */
  // eslint-disable-next-line func-names
  window.requestAnimationFrame = window.requestAnimationFrame || function (c) {
    return setTimeout(() => {
      c(+new Date());
    }, 30);
  };
};

function main() {
  executeOnReady();

  handle({
    classToggler,
    googleAnalyticsEventHandler,
  });

  enhance({
    accordionPanel,
    autocomplete,
    autoplayVideo,
    cookiebar,
    deleteProject,
    googleAnalyticsEventEnhancer,
    googleMaps,
    popover,
    listPanel,
    selectRedirect,
    videoEmbedder,
  });
}

domready(() => {
  main();
});
