// eslint-disable */
import { getCurrentBreakpoint } from './responsive';
import { register as scrollListener, isInView } from './scroll-listener';

let count = 0;

const playVideo = (video) => {
  video.load();
  const play = () => {
    video.play();
    video.removeEventListener('canplaythrough', play, false);
  };
  if (video.readyState > 3) {
    play();
  }
  video.addEventListener('canplaythrough', play, false);
};

const appendVideo = (el) => {
  const size = getCurrentBreakpoint() === 'extraLarge' ? 'large' : getCurrentBreakpoint();
  const src = el.getAttribute(`data-video-${size}`);
  const poster = el.getAttribute(`data-poster-${size}`);
  const video = document.createElement("video");
  video.setAttribute('src', `${src}`);
  video.setAttribute('poster', `${poster}`);
  video.setAttribute('loop', 'loop');
  video.setAttribute('muted', 'muted');
  video.setAttribute('playsinline', 'playsinline');
  video.setAttribute('webkit-playsinline', 'webkit-playsinline');
  video.setAttribute('preload', 'auto');
  el.insertBefore(video, el.childNodes[0]);
  // check for bounding element is visible...
  const boundingEl = el.length ? el : video;
  if (isInView(boundingEl)) {
    playVideo(video);
  }

  // attach scroll listener, and play pause the video when necessary
  /* eslint-disable */
  const current = count++;
  const scrollHandler = (e) => {
    const visible = isInView(boundingEl);
    if (visible && video.paused) {
      video.play();
    } else if (!visible && !video.paused) {
      video.pause();
    }
  };
  const listener = scrollListener(`autoplay-video-${current}`, scrollHandler);
};

export const enhancer = (el) => {
  appendVideo(el);
};
