export const enhancer = (el) => {
  const title = el.getElementsByClassName('accordion__panel-title');
  for (let i = 0; i < title.length; i += 1) {
    title[i].parentElement.classList.add('accordion__panel--collapse');
    title[i].addEventListener('click', function(e) {
      const item = this.parentElement;
      if (item.classList.contains('accordion__panel--collapse')) {
        item.classList.remove('accordion__panel--collapse');
        item.classList.add('accordion__panel--expanded');
      } else {
        item.classList.remove('accordion__panel--expanded');
        item.classList.add('accordion__panel--collapse');
      }
    });
  }
};
