export const enhancer = (el) => {
  const img = el.getElementsByTagName('img')[0];
  el.style.backgroundImage = `url('${img.src}')`;
  img.parentNode.removeChild(img);
  el.addEventListener('click', (e) => {
    e.preventDefault();
    const iframe = document.createElement('iframe');
    iframe.src = el.getAttribute('data-src');
    iframe.setAttribute('frameborder', 0);
    el.appendChild(iframe);
    el.replaceWith(iframe);
  });
};
