export const getCookie = () => {
  /* globals cps_vars */
  return cps_vars.cookie;
};

export const createCookieBar = (cookie) => {
  const el = document.createElement('div');
  el.classList.add('cookiebar');
  el.innerHTML = `<div class="cookiebar__inner"><span class="button button-inverse cookiebar__button js_cookiebar-accept">${cookie.close_text}</span> ${cookie.text}</div>`;
  document.body.appendChild(el);
  return el;
};

export const enhancer = (el) => {
  // check if session storage exists
  if (localStorage) {
    const cookie = getCookie();
    // get data and at to browsersession
    if (!localStorage.getItem(cookie.name)) {
      const cookieBar = createCookieBar(cookie);
      const closeButton = document.getElementsByClassName('js_cookiebar-accept')[0];
      closeButton.addEventListener('click', e => {
        cookie.date_accepted = Date.now();
        localStorage.setItem(cookie.name, JSON.stringify(cookie));
        cookieBar.remove();
      });
    }
  }
};
