export const enhancer = el => {
  if (document.getElementsByClassName('cookiebar').length || sessionStorage.getItem('popover')) {
    el.remove();
  }
  const closeButton = el.querySelector('.popover__close');
  closeButton.addEventListener('click', (e) => {
    el.remove();
    sessionStorage.setItem('popover', 'hidden');
  });
};
