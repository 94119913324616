import { getCurrentBreakpoint } from "./responsive";

export const listCollapse = el => {
  const titles = el.getElementsByClassName("list-panel__title");
  for (let i = 0; i < titles.length; i += 1) {
    const title = titles[i];
    title.parentElement.classList.add("list-panel__item--collapse");
    title.addEventListener("click", (e) => {
      const item = this.parentElement;
      if (item.classList.contains("list-panel__item--collapse")) {
        item.classList.remove("list-panel__item--collapse");
        item.classList.add("list-panel__item--expanded");
      } else {
        item.classList.remove("list-panel__item--expanded");
        item.classList.add("list-panel__item--collapse");
      }
    });
  }
};

export const listTab = el => {
  el.classList.toggle("list-panel__collapse");
  el.classList.toggle("list-panel__tabs");

  const div = document.createElement("div");
  div.classList.add("list-panel__tab-items");
  el.insertBefore(div, el.childNodes[0]);

  const titles = el.getElementsByClassName("list-panel__title");
  const content = el.getElementsByClassName("list-panel__item");

  for (let i = 0; i < titles.length; i += 1) {
    const title = titles[i];
    div.appendChild(title);
    if (i === 0) {
      title.classList.add("list-panel__item--active");
      content[i].classList.add("list-panel__item--expanded");
    } else {
      content[i].classList.add("list-panel__item--collapse");
    }

    title.addEventListener("focus", (e) => {
      for (let j = 0; j < titles.length; j += 1) {
        titles[j].classList.remove("list-panel__item--active");
        content[j].classList.remove("list-panel__item--expanded");
        content[j].classList.add("list-panel__item--collapse");
      }
      const tab = title.getElementsByClassName("item")[0].getAttribute("data-tab");
      title.classList.add("list-panel__item--active");
      const item = el.querySelector(`[data-content="${tab}"]`).parentElement;
      item.classList.add("list-panel__item--expanded");
      item.classList.remove("list-panel__item--collapse");
    });
  }
};

export const enhancer = el => {
  /* eslint-disable no-unused-expressions */
  getCurrentBreakpoint() === "small"
    ? listCollapse(el)
    : listTab(el);
};
