/* eslint-disable */
export const enhancer = (el) => {
  el.classList.add('is-autocomplete-ready');
  let input; let filter; let list; let li; let a; let i; let count;
  input = el.getElementsByTagName('input')[0];
  input.setAttribute('autocomplete', 'off');
  list = el.getElementsByClassName('autocomplete-list')[0];

  input.addEventListener('keyup', function(){
    filter = input.value.toUpperCase();
    li = list.getElementsByTagName("li");
    count = 0;
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      if (a.innerHTML.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = '';
        count++;
      } else {
        li[i].style.display = 'none';
      }
    }
    list.style.display = 'block';
    if( count ) {
      list.classList.add('has-autocomplete-items');
    } else {
      list.classList.remove('has-autocomplete-items');
    }
  });
};
