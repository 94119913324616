/* eslint-disable */
import { importer } from "./importer";
import { googleMapsStyles } from "./google-maps-styles";

let map;

export const enhancer = el => {
  /**
     *  Import Google Maps API
     */
  importer
    .urls([
      "https://maps.googleapis.com/maps/api/js?key=" + cps_vars.google_maps_api_key
    ])
    .then(() => {
      loadMap();
    });
};

export const loadMap = () => {
  let center = new google.maps.LatLng(52.35453, 5.699158);
  let mapOptions = {
    zoom: 8,
    center: center,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControlOptions: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_TOP
    },
    styles: googleMapsStyles
  };

  map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);

  getMarkers();
};

export const getMarkers = () => {
  let request = new XMLHttpRequest();
  request.open("GET", cps_vars.ajax_url + "?action=portraits", true);

  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      var data = JSON.parse(request.responseText);
      if (data.markers !== null) {
        addToMap(data);
      }
    }
  };

  request.onerror = function() {
    // There was a connection error of some sort
  };

  request.send();
};

export const addToMap = data => {
  let markers = data.markers;
  for (let i = 0; i < markers.length; i++) {
    let marker = new google.maps.Marker({
      title: markers[i].name,
      position: new google.maps.LatLng(markers[i].lat, markers[i].lng),
      map: map,
      content: markers[i].content
    });
    marker.setIcon(data.icon);
    addInfoWindow(marker);
  }
};

export const addInfoWindow = marker => {
  let infowindow = new google.maps.InfoWindow({
    content: marker.content
  });

  marker.addListener("click", function() {
    infowindow.open(map, marker);
  });
};
